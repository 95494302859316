class InfoWindow
  constructor: (provider) ->
    @provider = provider

  getProvider: ->
    @provider

  getTemplate: ->
    document.getElementById('provider-template')

  getProviderPath: ->
    query = Object.assign({},window.filters)
    query['provider-distance'] = @provider.distance
    query = queryString.stringify(query)
    "/providers/#{@provider.id}?#{query}"

  getSidebarNode: (domID = null) ->
    geopins = ["/static/images/daycare_center_pin.png", "/static/images/daycare_home_pin.png"]
    provider = @getProvider()
    instance = @getTemplate().content.cloneNode(true)
    instance.querySelector('.provider-profile-image').innerHTML = "<img class='img-fluid' src='#{provider.profile_image_url}'>"
    instance.querySelector('.geopin').setAttribute('src', geopins["#{provider.state_license_type}"])
    instance.querySelector('.marker-provider-info').id = domID if domID
    instance.querySelector('.provider-name').textContent = provider.name

    instance.querySelector('turbo-frame').setAttribute('id', "favorite_toggle_provider_#{provider.id}")

    if provider.openings? && provider.openings.length > 0
      instance.querySelector('.provider-openings').textContent = "Openings Available"

    instance.querySelector('.provider-distance').textContent = provider.distance
    instance.querySelector('.provider-link').setAttribute('href', @getProviderPath())
    if instance.querySelector('.add-remove-favorite')
     
      base_path = instance.querySelector('turbo-frame > form').getAttribute('action')
      base_path = base_path.replace(/provider_id/, provider.id)
      path_with_distance = base_path + "?distance=#{provider.distance}"
      instance.querySelector('turbo-frame > form').setAttribute('action', path_with_distance)
      if provider.favorited
        favorited_icon = instance.querySelector('.add-remove-favorite > img').getAttribute('data-favorited-icon')
        instance.querySelector('.add-remove-favorite > img').src = favorited_icon
      instance.querySelector('.add-remove-favorite').setAttribute('action', "/users/provicers_favorites/#{provider.id}")
    instance.querySelector('.marker-provider-info-wrapper').setAttribute('data-providerurl', @getProviderPath())
    instance

  getMarkerNode: ->
    provider = @getProvider()
    instance = @getTemplate().content.cloneNode(true)

    instance.querySelector('.provider-profile-image').innerHTML = "<img style='height:100px;' src='#{provider.profile_image_url}'>"
    instance.querySelector('.provider-name').textContent = provider.name
    instance.querySelector('.provider-distance').textContent = provider.distance
    instance.querySelector('.provider-link').setAttribute('href', @getProviderPath())
    instance.querySelector('.provider-link').setAttribute('target', '_blank')
    instance.querySelector('.provider-link').textContent = 'Learn more ›'

    instance.addEventListener 'click', ->
      window.location(@getProviderPath())

    instance

export default InfoWindow
